










































import Vue from 'vue'
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'confirm-dialog',
  props: ['value'],
  methods: {
    handleOkBtnPressed(e: MouseEvent) {
      this.$emit('confirm', e);
      this.active = false;
    }
  },
  computed: {
    active: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    ...mapGetters('sales', ['amount', 'phone', 'selectedOperator', 'percentage']),
  }
})
